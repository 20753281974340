import React from 'react';
import { Link } from "gatsby"

const NextPrev = props => {

  const next = props.context.next
  const prev = props.context.prev

  return (
    <div className="container flex justify-between pt-16">
      <div>
        {
          prev !== null ?
          <Link to={`/${prev.slug}/`}>
            <h3 className="text-left">Previous:</h3>
            <h6 className="text-left">{prev.title}</h6>
          </ Link>
          : null
        }
      </div>
      <div>
      {
        next !== null ?
        <Link to={`/${next.slug}/`}>
          <h3 className="text-right">Next:</h3>
          <h6 className="text-right">{next.title}</h6>
        </ Link>
        : null
      }
      </div>
    </div>
  )
}

export default NextPrev;
