import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ShareSocialMedia from "../components/shareSocialMedia"
import NextPrev from "../components/nextPrev"

const PostTemplate = ({ data, pageContext }) => {
  const {
    author,
    title,
    description,
    slug,
    body,
    heroImage,
    category,
    publishDate,
  } = data.contentfulBlogPost

  return (
    <Layout>
      <Helmet>
        <title>{`${title}`}</title>
      </Helmet>
      <SEO title={title} image={heroImage.file.url} description={description.description}/>
      <div className="container mx-auto lg:my-20 px-4 lg:px-40">
        <Link to={`/category/${category.toLowerCase()}/`}>
          <div className="text-gray-500 py-4 hover:underline">{category}</div>
        </Link>
        <div className="text-4xl font-bold">{title}</div>
        <div className="pb-8 pt-2">
          <div className ="flex justify-between items-end w-64">
            { author.name ?
              <Link to={`/author/${author.name.toLowerCase()}/`}>
                <span>by </span><span className="hover:underline">{author.name}</span>
              </Link>
              :
              null
            }
            <div className="text-gray-500 text-sm">
              <span>{publishDate}</span>
            </div>
          </div>
        </div>
        <img className="w-screen h-35 object-cover" src={heroImage.file.url} alt={heroImage.title} />
        <div dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}} />
        <div className="container flex pt-16">
          {
            author.image ?
            <img className="rounded-full w-16 h-16 mr-6" src={author.image.file.url} alt={author.image.title}/>
            :
            null
          }
          <div>
            <Link to={`/author/${author.name.toLowerCase()}/`}>
              <h4 className="font-semibold hover:underline">{author.name}</h4>
            </Link>
            <p>{author.shortBio.shortBio}</p>
          </div>
        </div>
        <ShareSocialMedia title={title} slug={slug} description={description.description} heroImage={heroImage.file.url} />
        <NextPrev context={pageContext} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description {
        description
      }
      slug
      category
      body {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString:"DD MMMM, YYYY")
      heroImage {
        file {
          url
        }
        title
      }
      author {
        name
        image {
          file {
            url
          }
          title
        }
        shortBio {
          shortBio
        }
      }
    }
  }
`

export default PostTemplate
